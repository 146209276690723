<template>
  <section>
    <!--商品Detail-->
    <router-view name="list" v-if="$route.name == 'productDetail'"></router-view>

    <div v-show="$route.name == 'products'">
      <!-- PC -->
      <div class="hidden md:block relative">

        <!-- 類別、搜尋框 -->
        <div class="w-full bg-pink-salmon fixed z-50">
          <div class="max-w-1200 mx-auto flex justify-between items-center h-14">
            <ul class="flex h-full mb-0">
              <li @click="onTabsMenuChanged('hot')" class="w-165px text-white text-lg px-6 cursor-pointer h-full items-center flex justify-end" :class="{ 'bg-pink-salmon-active': selectedKey == 'hot' }">
                當期商品
              </li>
              <li class="w-10"></li>
              <li
                @click="onTabsMenuChanged('all')"
                class="w-165px text-white text-lg px-6 cursor-pointer h-full items-center flex justify-center"
                :class="{ 'bg-pink-salmon-active': selectedKey == 'all' }"
              >
                全部商品
              </li>
            </ul>
            <div class="c-search w-auto" v-if="selectedKey == 'all'">
              <img src="../../assets/img/icon_search.svg" class="inline-block h-4 w-4 border-0" />
              <a-input v-model="form.q" placeholder="請輸入商品關鍵字" allow-clear @keydown.enter="handleSearch()"></a-input>
              <a-button @click="handleSearch()" type="normal" slot="enterButton" class="c-search-btn">
                搜尋
              </a-button>
            </div>
          </div>
        </div>

        <div class="max-w-1200 p-top-keep mx-auto pb-12 md:px-10 lg:px-0 flex justify-between">
          <div class="w-165px">
            <div class="mr-4" :class="{ isFixed: !scrollOnTop }">
              <div v-show="selectedKey == 'all'">
                <!-- get data from API -->
                <p class="w-165px mb-2 text-right text-xl font-bold">商品分類</p>
                <div class="w-165px pr-2 pc-overflow-scroll">
                  <ul class="text-right">
                    <li
                      v-for="menu in subMenu"
                      :key="menu.index"
                      @click="handleClickMenus(menu.index)"
                      class="pl-2 text-lg cursor-pointer mb-2"
                      :class="{ 'sub-menu--active': selectedSubMenu === menu.index }"
                    >
                      {{ menu.name }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="w-10"></div>
          <div class="flex-1">
            <div class="flex justify-between">
              <p class="text-3xl font-bold">
                {{ textMap[selectedKey] }}
                <span class="text-xl ml-4">{{ categoryText }}</span>
              </p>
              <div v-if="selectedKey == 'all'" class="flex justify-around items-center">
                <a-radio-group v-model="selectSort" default-value="0" size="large">
                  <a-radio-button value="0">最新上架</a-radio-button>
                  <a-radio-button value="2">人氣排行榜</a-radio-button>
                  <a-radio-button value="3">金額高</a-radio-button>
                  <a-radio-button value="4">金額低</a-radio-button>
                </a-radio-group>
              </div>
            </div>

            <!--全部商品-->
            <template v-if="this.selectedKey == 'all'">
              <product-item v-if="allProducts" :items="allProducts" :showBanner="true" :bannerList="productBannerList"></product-item>
            </template>

            <!--當期商品-->
            <template v-if="this.selectedKey == 'hot'">
              <product-item v-if="hotProducts" :items="hotProducts" :showBanner="false" :bannerList="productBannerList"></product-item>
            </template>

            <!-- PC loading-->
            <section class="bg-gray-100 h-10 flex justify-center md:bg-white">
              <a-spin v-if="loading">
                <a-icon slot="indicator" type="loading" style="font-size: 24px" spin />
              </a-spin>
            </section>
          </div>
        </div>
      </div>

      <!-- Mobile -->
      <div class="fa-wrap block md:hidden relative">
        <!--Modal選擇分類-->
        <a-modal transitionName="fade" class="ant-modal-bottom" title="選擇分類" :closable="false" v-model="modals.category.isShow">
          <!-- <template v-if="initDone"> -->
            <article class="border-b mb-1 pb-2 modal-overflow">
              <a-radio-group v-model="modals.category.id" class="pills">
                <a-radio-button v-for="(c, cIdx) in categoriesData" :key="cIdx" :value="c.id" @click="onModalCategoryRadio($event, c.name, cIdx)">
                  {{ c.name }}
                </a-radio-button>
              </a-radio-group>
            </article>
          <!-- </template> -->
          <template slot="footer">
            <!--按鈕-->
            <div class="btn-bottom-groups">
              <button class="btn-forms btn-light" @click="onModalCategoriesCancel">取消</button>
              <button class="btn-forms" @click="onModalCategoryOK(modals.category.index)">確定</button>
            </div>
          </template>
        </a-modal>
        <article class="app-page">
          <div class="bg-gray-100 min-h-screen h-full rounded-t-3xl">
            <div class="">
              <a-tabs class="abc" v-model="selectedKey" @change="onTabsMenuChanged">
                <a-tab-pane key="all" tab="全部商品">
                  <!--search-->
                  <div class="c-search-wrapper w-full bg-pink-salmon-200 px-5 py-3" :class="{ 'is-fixed': search.isFixed | modals.category.isShow }">
                    <div class="c-search mb-2">
                      <img src="../../assets/img/icon_search.svg" class="inline-block h-4 w-4 border-0" />
                      <a-input v-model="form.q" placeholder="請輸入商品關鍵字" @keydown.enter="handleSearch()" allow-clear></a-input>
                      <a-button @click="handleSearch()" type="normal" slot="enterButton" class="c-search-btn">
                        搜尋
                      </a-button>
                    </div>
                    <div class="c-search c-search-category" @click="onCategoriesModal">
                      <img src="../../assets/img/icon_category.svg" class="inline-block h-4 w-4 border-0" />
                      <div class="pointer-events-none w-full">
                        <a-input v-model="categoryText" placeholder="請選擇分類"></a-input>
                      </div>
                      <img class="h-2 transform" src="../../assets/img/ui/ui_triangle_down.svg" />
                    </div>
                  </div>

                  <div v-if="selectedKey == 'all'" class="flex justify-around mt-2">
                    <a-radio-group v-model="selectSort" default-value="0" size="small" >
                      <a-radio-button value="0">最新上架</a-radio-button>
                      <a-radio-button value="2">人氣排行榜</a-radio-button>
                      <a-radio-button value="3">金額高</a-radio-button>
                      <a-radio-button value="4">金額低</a-radio-button>
                    </a-radio-group>
                  </div>

                  <div class="container-wrapper">
                    <product-item :items="allProducts" :showBanner="true" :bannerList="productBannerList"></product-item>
                  </div>
                </a-tab-pane>

                <a-tab-pane key="hot" tab="當期商品" force-render>
                  <div class="container-wrapper">
                    <!--當期商品-->
                    <product-item v-if="hotProducts" :items="hotProducts" :showBanner="false" :bannerList="productBannerList"></product-item>
                  </div>
                </a-tab-pane>
              </a-tabs>
            </div>

            <!-- mobile loading-->
            <section class="bg-gray-100 h-10 flex justify-center md:bg-white">
              <a-spin v-if="loading">
                <a-icon slot="indicator" type="loading" style="font-size: 24px" spin />
              </a-spin>
            </section>
          </div>

          <!--下方按鈕-->
          <!-- <section class="fixed w-full bottom-5 flex justify-center items-center" v-if="showBottomBtn">
            <button
              class="btn-forms bottom"
              @click="
                handleGetDataAllOrHot(selectedKey, false)
                showBottomBtn = false
              "
              :disabled="this.loading"
            >
              看更多
            </button>
          </section> -->
        </article>
      </div>
    </div>
  </section>
</template>

<script>
import ProductItem from './ProductItem.vue'
import { mapGetters, mapActions } from 'vuex'
import bodyScrollLock from '@/utils/bodyScrollLock.js' //modal開啟時鎖住body
// import omitBy from 'lodash/omitBy'
// import isEmpty from 'lodash/isEmpty'
// import  {debounce} from 'lodash';

export default {
  name: 'productsList',
  data() {
    return {
      loading: false, //讀取下一頁資料的圈圈
      showBottomBtn: false, //到底了嗎?
      scrollOnTop: true,
      form: {
        q: '', // string, max: 128 查詢字串
        category: '', // integer 分類ID
        page: 0, // integer 第幾頁
        sort: 0, //排序依據
        sortType: 1
      },
      search: {
        isFixed: false,
        position: null
      },
      selectedKey: 'all', // all/hot pc,mobile的 全部商品/當期
      textMap: {
        hot: '當期商品',
        all: '全部商品'
      },
      selectedSubMenu: '', // 數字 categoryData index 正在選擇的分類
      subMenu: [],
      modals: {
        category: {
          isShow: false,
          id: '',
          index: '',
          name: ''
        }
      },
      categoriesData: [],
      // initDone: false,
      savedPosition: '',
      currentDetail: '', //現在是不是在商品詳細頁面  products/:id
      selectSort: '0',
      sortType: '1',
      flag: undefined //隱形賣場
    }
  },
  computed: {
    ...mapGetters(['categories', 'allProducts', 'hotProducts', 'minusOnePage', 'productBannerList', 'user']),
    categoryText() {
      // return this.selectedSubMenu == '' ? '' : this.subMenu.find(item => item.index === this.selectedSubMenu).text
      return this.selectedSubMenu == '' ? '全部' : this.categoriesData[this.selectedSubMenu].name
    }
  },
  components: {
    ProductItem
  },
  watch: {
    $route(to,from) {
      if(to.name == 'productDetail') {
        // this.initDone = false
        this.savedPosition = this.search.position //存取上次的scroll位置
        this.currentDetail = true //商品內頁
      }
      else if(from.name == 'productDetail') {
        console.log('從productDetail進入此頁面')
        this.currentDetail = false //商品列表
        this.scrollToSavedPosition()
        //商品列表scroll離上方的距離 從商品頁返回時要讀取
      }
    },
    '$route.query.selectedKey': {
      //menu, tabs切換分類時 query值都會變更
        handler: function(selectedKeyValue) {
          if(selectedKeyValue){
          console.log('watch: Has query selectedKey')

            this.currentDetail = false
            this.savedPosition = 0
            this.scrollToSavedPosition()

            this.selectedKey = selectedKeyValue
            this.selectedSubMenu = 0 //PC 選單設為全部
            this.form.category = '' //清空search的分類
            //* api 取得商品
            this.handleGetDataAllOrHot(selectedKeyValue, true)
          }

        },
        deep: true,
        immediate: true
    },
    // '$route.query.flag': {
    //   //menu, tabs切換分類時 query值都會變更
    //     handler: function(flagValue) {
    //       if(flagValue){
    //         console.log('watch: Has query flag')

    //         this.currentDetail = false
    //         this.savedPosition = 0
    //         this.scrollToSavedPosition()

    //         this.flag = flagValue
    //         this.selectedSubMenu = 0 //PC 選單設為全部
    //         this.form.category = '' //清空search的分類
    //         //* api 取得商品
    //         this.handleGetDataAllOrHot(this.selectedKey, true, flagValue)
    //       }

    //     },
    //     deep: true,
    //     immediate: true
    // },
    'modals.category.isShow': {
      deep: true,
      immediate: true,
      async handler(value) {
        if (value == true) {
          //防止手機背景滑動
          await bodyScrollLock.enable()
          await document.querySelector('.c-search-wrapper').classList.add('is-fixed')
        } else {
          await bodyScrollLock.disable()
          await document.querySelector('.c-search-wrapper').classList.remove('is-fixed')
        }
      }
    },
    'search.isFixed': {
      deep: true,
      immediate: true,
      async handler(value) {
        if (value == true && document.querySelector('.ant-tabs-nav-container')) {
          document.querySelector('.ant-tabs-nav-container').classList.add('is-fixed')
        } else if(value == false && document.querySelector('.ant-tabs-nav-container'))
        {
          document.querySelector('.ant-tabs-nav-container').classList.remove('is-fixed')
          
        }
      }
    },
    'minusOnePage': {
        handler: function() {
          this.form.page -= 1
          console.log('minusOnePage 減少1page後的結果:', this.form.page)
        },
        immediate: true
    },
    'selectSort': {
      immediate: false,
      async handler(value) {
        if(this.selectedKey == 'all') {
          if(value == '0') {
            this.form.sort = 0
            this.form.sortType = 1
          }
          if(value == '2') {
            this.form.sort = 2
            this.form.sortType = 1
          }
          if(value == '3') {
            this.form.sort = 1
            this.form.sortType = 1
          }
          if(value == '4') {
            this.form.sort = 1
            this.form.sortType = 0
          }

          await this.clearProductsAll()
          await this.handleGetDataAllOrHot(this.selectedKey, true)
        }
      }
    },
    'selectedKey': {
      immediate: true,
      handler(value) {
        if(value == 'hot') {
          this.form.sort = 0
          this.form.sortType = 1
          this.selectSort = "0"
        }
      }
    }
  },
  methods: {
    ...mapActions(['getCategories', 'getProductsAll','getProductsAllPageZero', 'getProductsHot','getProductsHotPageZero', 'cleanProductsStates', 'clearProductsAll', 'clearProductsHot', 'getProductBanner', 'getUser', 'getCartData']),
    async initCategories() {
      this.categoriesData.push({ key: 'all', id: '', name: '全部' }) //製作一個全部
      await this.getCategories() // 更新商品分類清單
      this.categoriesData = [...this.categoriesData, ...this.categories] //將store資料複製一份到本機
      this.categoriesData.forEach((x, idx) => {
        this.subMenu.push({ id: x.id, name: x.name, index: idx })
      })
    },
    onCategoriesModal() {
      this.modals.category.isShow = true
    },
    resetCategoryModals() {
      this.modals.category.id = ''
      this.modals.category.name = ''
      this.modals.category.index = ''
    },
    onModalCategoryRadio(e, name, index) {
      this.modals.category.id = e.target.value
      this.modals.category.name = name
      this.modals.category.index = index
    },
    onModalCategoriesCancel() {
      this.resetCategoryModals()
      this.modals.category.isShow = false //關跳彈
    },
    async onModalCategoryOK(categoriesIndex) {
      this.loading = false
      console.log(categoriesIndex)
      // * api 分類
      this.form.category = this.modals.category.id
      this.form.q = '' //分類清空字串
      await this.handleGetDataAllOrHot(this.selectedKey, true)
      this.selectedSubMenu = categoriesIndex
      this.modals.category.isShow = false //關跳彈
    },

    onTabsMenuChanged(key) {
      console.log('onTabsMenuChanged:', key)
      //pc mobile
      this.selectedKey = key
      this.form.category = ''
      this.selectedSubMenu = 0
      this.$router.replace({ query: { selectedKey: key } }).catch(err => err) //變更query觸發watch打api
    },
    async handleClickMenus(key) {
      console.log('handleClickMenus', key)
      //pc
      this.selectedSubMenu = key
      let id = this.categoriesData[key].id
      this.form.category = id
      //* api 分類 (pc menu)
      this.form.q = '' //分類清空字串
      this.loading = true
      await this.handleGetDataAllOrHot('all', true)
      this.loading = false
    },
    async handleSearch() {
      //* api 商品關鍵字搜尋
      // const params = omitBy(this.form, isEmpty)
      this.form.page = 0
      this.form.category = ''
      this.selectedSubMenu = 0
      await this.clearProductsAll()
      await this.getProductsAll(this.form)
    },
    scrollFixedSearch() {
      if (this.currentDetail === true) {
        return
      }
      // 如果search當下距離畫面60px,就會置頂
      this.search.position = window.pageYOffset || document.documentElement.scrollTop
      if (this.search.position > 60) {
        this.search.isFixed = true
        this.scrollOnTop = false
      } else {
        this.search.isFixed = false
        this.scrollOnTop = true
      }
    },
    async scrollToSavedPosition() {
      this.$nextTick(function() {
        document.documentElement.scrollTop = this.savedPosition
      })
    },
    async scrollBottomGetData() {
      if (this.currentDetail === true) {
        return
      }
      var scrollTop = document.documentElement.scrollTop || document.body.scrollTop //windowHeight是可视区的高度
      var windowHeight = document.documentElement.clientHeight || document.body.clientHeight //scrollHeight是滚动条的总高度
      var scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight //滚动条到底部的条件
      if (scrollTop + windowHeight >= scrollHeight - 300 ) { // 300 提高定位點
        // *api 取得資料
        if (this.loading == false) {
          this.showBottomBtn = true
          this.handleGetDataAllOrHot(this.selectedKey, false)
        }
      }
    },
    async handleGetDataAllOrHot(key, getPageZero) {
      //api ==========================//
      /**
       * @description handleGetDataAllOrHot
       * @param {string} key
       * all 全部商品api
       * hot 當期商品api
       * @param {boolean} getPageZero
       * true - 讀取page:0資料
       * false - 讀取下一頁資料
       */
      if (getPageZero) console.log(`handleGetDataAllOrHot 商品:${key} 取page 0`)
      if (!getPageZero) console.log(`handleGetDataAllOrHot 商品:${key} 取page ${this.form.page}`)

      if (getPageZero == true) {
        switch (key) {
          case 'all':
            console.log('handleGetDataAllOrHot all')
            this.form.page = 0
            this.loading = true
            await this.getProductsAllPageZero(this.form)
            this.loading = false
            break
          case 'hot':
            this.form.page = 0
            this.loading = true
            await this.getProductsHotPageZero(this.form)
            this.loading = false
            break
        }
      }

      //滾到底
      else if (getPageZero == false) {
        switch (key) {
          case 'all':
            if (this.loading == true) return //如果還在loading就跳出
            this.loading = true
            this.form.page += 1
            await this.getProductsAll(this.form)
            this.loading = false
            break
          case 'hot':
            if (this.loading == true) return //如果還在loading就跳出
            this.form.page += 1
            this.loading = true
            await this.getProductsHot(this.form)
            this.loading = false
            break
        }
      }
    },
    handleRedirect() {
      switch (this.user.status) {
        case -1:
          this.$router.push({ name: 'personal', query: { desc: 1 } })
          break
        case 1:
          console.log('status 1')
          if(sessionStorage.getItem('afterlogin-to') != null) {
            this.$router.push(sessionStorage.getItem('afterlogin-to'))
            sessionStorage.removeItem('afterlogin-to')
          }
          break
        default:
          this.$router.push('/status')
          break
      }
    }
  },
  created() {
    //console.log(this.$route.query.flag)

    //是不是在商品詳細頁面
    if (this.$route.name == 'productDetail') {
      console.log('商品詳細')
      this.currentDetail = true //顯示商品詳細
    } else if (this.$route.name == 'products') {
      console.log('商品列表')
      this.currentDetail = false
    }
  },
  async mounted() {
    await this.getUser()
    await this.getCartData()
    await this.handleRedirect()

    window.addEventListener('scroll', this.scrollFixedSearch)
    window.addEventListener('scroll', this.scrollBottomGetData, true)
    await this.initCategories()
    if (this.allProducts.length == 0) {
      console.log('this.allProducts.length == 0')
      this.handleGetDataAllOrHot('all', true)
    }
    if (this.hotProducts.length == 0) {
        console.log('this.hotProducts.length == 0')
      this.handleGetDataAllOrHot('hot', true)
    }
    // this.initDone = true

    this.getProductBanner()
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.scrollFixedSearch)
    window.removeEventListener('scroll', this.scrollBottomGetData, true)
  }
}
</script>

<style scope lang="postcss">
/*搜尋樣式*/

/*當menu打開時*/
.scroll-disabled .ant-tabs-nav-container.is-fixed,
.scroll-disabled .c-search-wrapper.is-fixed{
  z-index: 5 !important;
}

.ant-tabs-nav-container.is-fixed {
  position: fixed;
  top: 51px;
  left: 0;
  width: 100%;
  z-index: 1000 !important; /* 1001是menu的背後背景,不能超過1000以上*/
  -webkit-overflow-scrolling: touch;
}
.is-fixed .ant-tabs-nav-scroll {
  border-radius: 0;
}

.c-search-wrapper.is-fixed {
  position: fixed;
  top: 86px;
  left: 0;
  width: 100%;
  z-index: 1000 !important; /* 1001是menu的背後背景,不能超過1000以上*/
  -webkit-overflow-scrolling: touch;
}
.c-search {
  box-sizing: border-box;
  margin: 0;
  padding: 0 0 0 1rem;
  width: 100%;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 18px;
}
.c-search .ant-btn {
  height: auto !important;
}
.c-search .ant-input:focus {
  box-shadow: none;
}
.c-search .ant-btn:hover,
.c-search .ant-btn:focus {
  color: #ff8d9d;
}
.c-search-btn,
.c-search-btn:active,
.c-search-btn:focus {
  background: transparent;
  border-left: 1px solid #dedede !important;
  border-radius: 0;
  border: 0;
  box-shadow: none;
  color: #ff8d9d;
}

/* category 分類*/
.c-search.c-search-category {
  padding: 0 1rem;
}

.sub-menu--active {
  @apply text-pink-salmon;
  @apply relative;
}

.sub-menu--active::before {
  content: '';
  @apply absolute;
  @apply left-0;
  @apply w-1;
  @apply h-full;
  @apply bg-pink-salmon;
}

/* pc版 全部商品時左邊顯示分類 */
.w-165px {
  width: 165px;
}
.pc-overflow-scroll {
  height: calc(100vh - 230px);
  overflow: auto;
}
.pc-overflow-scroll::-webkit-scrollbar {
  width: 2px;
}
.pc-overflow-scroll::-webkit-scrollbar-thumb {
  background-color: #dedede;
}
.isFixed {
  position: fixed;
  /* top: 1rem; */
  /* top: 150px; */
}

.p-top-keep {
  padding-top: 65px;
}
</style>
