<template>
  <div>
    <!-- 輪播 -->
    <div class="slick-box" v-if="bannerList.length > 0 && showBanner">
      <slick ref="productSlick" :options="slickOptions">
        <!-- <img src="../../assets/img/join/banner/0.png" />
        <img src="../../assets/img/join/banner/1.png" />
        <img src="../../assets/img/join/banner/2.png" />
        <img src="../../assets/img/join/banner/3.png" /> -->
        <a class="banner-item" v-for="(banner, index) in bannerList" :key="index" :href="banner.link == '' ? '#' : banner.link">
          <img :src="banner.image" />
        </a>
      </slick>
    </div>
    <div class="grid md:grid-cols-2 lg:grid-cols-4 md:gap-8">
      <!--商品-->
      <section class="mt-5 mb-5 md:m-0" v-for="(item, index) in this.items" :key="index">
        <div @click="goProductDetail(item)" class="c-panel shadow relative cursor-pointer">
          <!--標籤-->
          <article class="c-label-area md:top-0 w-1/2" v-if="item.spans">
            <label v-for="(n, nIndex) in 3" :key="n" class="block mb-1">
              <template v-if="item.spans[nIndex]">
                <div class="ribon-img start relative" :class="[nIndex % 2 === 0 ? 'yellow' : 'pink']">
                  <p class="ribon-text truncate">{{ item.spans[nIndex].name }}</p>
                  <div class="ribon-img end absolute" :class="[nIndex % 2 === 0 ? 'yellow' : 'pink']"></div>
                </div>
              </template>
            </label>
          </article>
          <div class="c-product-image-container -mt-12 mb-4 md:mt-0 mb:mb-0 rounded-tl-lg rounded-tr-lg">
            <img class="c-product-image rounded-tl-lg rounded-tr-lg" :src="item.image" :onerror="imgNotFound" />
          </div>

          <h2 class="c-product-title text-2xl md:text-lg text-center md:text-left mb-3 md:px-4">{{ item.name }}</h2>
          <article class="flex content-center justify-center md:px-4 md:mb-3">
            <div class="inline-block mr-2 self-center">
              <label class="bg-pink-salmon text-white rounded-md py-1 px-2">售價</label>
            </div>
            <div class="flex content-center items-center text-pink-salmon">
              <h4 class="text-3xl md:text-xl font-bold text-pink-salmon">{{ item.price.min }}</h4>
              <template v-if="item.price.min !== item.price.max">
                <span class="text-xl mx-2 md:text-base md:mx-0">~</span>
                <h4 class="text-3xl md:text-xl font-bold text-pink-salmon">{{ item.price.max }}</h4>
              </template>
            </div>
          </article>

          <!--商品剩餘量-->
          <article :class="{ 'h-10': !item.stock }" class="w-full">
            <div class="c-lastItem-area" :class="{ 'bg-white': item.stock == true }">
              <span class="p-2 text-center flex justify-center items-center" v-if="item.stock == true"></span>
              <span class="text-pink-salmon p-2 text-center flex justify-center items-center" v-if="item.stock == false">商品已售盡</span>
            </div>
          </article>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { productClickEvent } from '@/utils/ga.js'
import Slick from 'vue-slick'
import 'slick-carousel/slick/slick.css'

export default {
  name: 'ProductItem',
  props: ['items', 'scrollPosition', 'showBanner', 'bannerList'],
  data() {
    return {
      imgNotFound: 'this.src="' + require('../../assets/img/status/not_found_img.jpg') + '"',
      slickOptions: {
        slidesToShow: 1,
        slidesToScroll: 1,
        // centerMode: true,
        // centerPadding: '30px',
        arrows: false,
        autoplay: true,
        autoplaySpeed: 3000,
        dots: true
      }
    }
  },
  watch: {
    bannerList() {
      this.$refs.productSlick.destroy()
      this.$nextTick(() => {
        // if(this.bannerList.length > 3) this.slickOptions.slidesToShow = 1
        this.$refs.productSlick.create(this.slickOptions)
        // this.$refs.productSlick.$el.classList.add('cmm-slick-slider')
      })
    }
  },
  methods: {
    goProductDetail({ name, id, price }) {
      this.$gtm.trackEvent(
        productClickEvent({
          name,
          id,
          price: price.max
        })
      )
      console.log('id', id)
      this.$router.push({ path: `/products/${id}` })
      this.$route.params.id = id
    }
  },
  components: {
    Slick
  }
}
</script>

<style lang="postcss" scoped>
/*白版*/
.c-panel {
  @apply bg-white;
  @apply p-5;
  @apply md:p-0;
  @apply flex;
  @apply flex-col;
  /* @apply shadow; */
  @apply rounded-md;
  padding-top: 3rem;
}
</style>

<style lang="postcss">
/*商品圖*/

.c-product-image-container {
  position: relative;
  width: 100%;
}

.c-product-image-container:after {
  content: '';
  display: block;
  padding-bottom: 100%; /* The padding depends on the width, not on the height, so with a padding-bottom of 100% you will get a square */
}

.c-product-image {
  position: absolute; /* Take your picture out of the flow */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; /* Make the picture taking the size of it's parent */
  width: 100%; /* This if for the object-fit */
  height: 100%; /* This if for the object-fit */
  object-fit: cover; /* Equivalent of the background-size: cover; of a background-image */
  object-position: center;
}
/*商品標題*/
.c-product-title {
  color: #513e41;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

/*標籤靠左*/
.c-label-area {
  position: absolute;
  top: 15px;
  left: 0;
  color: unset;
  z-index: 9;
}

/*商品剩餘量*/
.c-lastItem-area {
  @apply absolute;
  @apply bg-pink-salmon-100;
  @apply rounded-b-md;
  @apply w-full;
  @apply bottom-0;
  @apply left-0;
}

.slick-box {
  display: grid;
  grid-template-columns: repeat(1,minmax(0,1fr));
}

.slick-box img {
  /* padding: 0 10px; */
  width: 100%;
}

.slick-dots {
  background-color: transparent; /* bg color of container */
  display: flex;
  justify-content: center;
  margin-bottom: 0;
}

.slick-dots button {
  color: transparent;
}

.slick-dots button:before {
  color: #ff8d9d;
  opacity: .25;
  background-color: transparent;
  content: "•";
  font-size: 20px;
}
.slick-dots .slick-active button:before {
  opacity: 1;
}

.banner-item {
  /* padding: 0 10px; */
}
</style>
